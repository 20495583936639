import * as XLSX from "xlsx";
import { normalizePermitNumber } from "./permitUtils";
import { parseExcelDate } from "./dateUtils";

interface FileType {
  name: string;
  type: string;
  requiredColumns: string[];
  paymentType?: string;
}

const validateExcelFile = async (
  file: File,
  fileType: FileType,
  cabMapping: Record<string, number>,
  tripTypeMapping: { typeToId: Record<string, number> }
): Promise<{
  isValid: boolean;
  totalAmount: number;
  error?: string;
  processedData?: any[];
}> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData: Record<string, any>[] =
          XLSX.utils.sheet_to_json(worksheet);

        if (jsonData.length === 0) {
          resolve({
            isValid: false,
            totalAmount: 0,
            error: "Excel-filen er tom",
          });
          return;
        }

        const headers = Object.keys(jsonData[0]);
        const missingColumns = fileType.requiredColumns.filter(
          (col) => !headers.some((h) => h.toLowerCase() === col.toLowerCase())
        );

        if (missingColumns.length > 0) {
          resolve({
            isValid: false,
            totalAmount: 0,
            error: `Mangler påkrevde kolonner: ${missingColumns.join(", ")}`,
          });
          return;
        }

        let totalAmount = 0;
        const processedData: {
          CreatedDateTime: string;
          CabID: number;
          Price: number;
          TripType: number;
        }[] = [];

        for (const row of jsonData) {
          const licenseKey = row["License"]
            ? "License"
            : row["Løyve"]
            ? "Løyve"
            : null;
          if (!licenseKey || !row[licenseKey]) continue;

          if (
            fileType.paymentType &&
            row["Payment type"]?.toLowerCase() !==
              fileType.paymentType.toLowerCase()
          ) {
            console.warn(`Hopper over rad pga. feil Payment Type:`, row);
            continue;
          }

          const normalizedLicense = normalizePermitNumber(row[licenseKey]);
          const cabId =
            cabMapping[normalizedLicense] || cabMapping[row[licenseKey]] || 0;

          const value = parseFloat(
            row["TotalSum"] || row["Taxam."] || row["Fare"] || "0"
          );
          if (isNaN(value) || value <= 0) continue;

          const dateField =
            row["Start_Dato"] || row["Start date"] || row["Turliste"];
          const formattedDate = parseExcelDate(dateField);

          if (!formattedDate) continue;

          totalAmount += value;
          processedData.push({
            CreatedDateTime: formattedDate,
            CabID: cabId,
            Price: value,
            TripType: tripTypeMapping.typeToId[fileType.type] || 1,
          });
        }

        if (processedData.length === 0) {
          resolve({
            isValid: false,
            totalAmount: 0,
            error: "Ingen gyldige rader funnet i filen",
          });
          return;
        }

        resolve({ isValid: true, totalAmount, processedData });
      } catch (error) {
        resolve({
          isValid: false,
          totalAmount: 0,
          error: "Ugyldig Excel-fil",
        });
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export default validateExcelFile;
