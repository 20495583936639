import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useCabMapping } from "../hooks/useCabMapping";
import { useTripTypes } from "../hooks/useTripTypes";
import SingleFileDropzone from "../components/upload/SingleFileDropZone";
import validateExcelFile from "../functions/_utils/excelValidation";

interface FileType {
  name: string;
  type: string;
  requiredColumns: string[];
  paymentType?: string;
}

interface UploadedFile {
  name: string;
  status: "validating" | "valid" | "invalid";
  error?: string;
  data?: any[];
  totalAmount?: number;
}

interface JwtPayload {
  role: string;
}

const FILE_TYPES: Record<string, FileType> = {
  pasientreiser1: {
    name: "Pasientreiser 1",
    type: "Pasientreiser",
    requiredColumns: ["Løyve", "Pasientnavn", "TotalSum", "Start_Dato"],
  },
  pasientreiser2: {
    name: "Pasientreiser 2",
    type: "Pasientreiser",
    requiredColumns: ["Løyve", "Pasientnavn", "TotalSum", "Start_Dato"],
  },
  skole: {
    name: "Skole",
    type: "Skole",
    requiredColumns: [
      "Payment type",
      "License",
      "Business",
      "Start date",
      "Fare",
    ],
  },
  cash: {
    name: "Cash",
    type: "Cash",
    requiredColumns: [
      "Payment type",
      "License",
      "Business",
      "Start date",
      "Fare",
    ],
    paymentType: "Cash",
  },
  "general-credit": {
    name: "General Credit",
    type: "General Credit",
    requiredColumns: [
      "Payment type",
      "License",
      "Business",
      "Start date",
      "Fare",
    ],
    paymentType: "General Credit",
  },
  "credit-card": {
    name: "Credit Card",
    type: "Credit Card",
    requiredColumns: [
      "Payment type",
      "License",
      "Business",
      "Start date",
      "Fare",
    ],
    paymentType: "Credit Card",
  },
  tt: {
    name: "TT",
    type: "TT",
    requiredColumns: ["Turliste", "Taxam.", "License"],
  },
};

export function FileUpload() {
  const history = useHistory();
  const [files, setFiles] = useState<Record<string, UploadedFile>>({});
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);

  const userString = localStorage.getItem("user");
  const user_data = userString ? JSON.parse(userString) : null;
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;

    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  if (!user_data || !user_data.token) {
    localStorage.clear();
    history.replace("/login");
  } else {
    try {
      const decodedToken = jwt_decode<JwtPayload>(user_data.token);

      if (!decodedToken || !decodedToken.role) {
        throw new Error("Invalid token");
      }

      const role = parseInt(decodedToken.role, 10);
      if (role !== 1) {
        history.replace("/");
      }
    } catch (error) {
      console.error("Feil ved dekoding av token:", error);
      localStorage.clear();
      history.replace("/login");
    }
  }

  const { cabMapping, isLoading: isCabMappingLoading } = useCabMapping();
  const { tripTypeMapping, isLoading: isTripTypesLoading } = useTripTypes();

  const handleNavigateToSummary = () => {
    history.push("/summary");
  };

  const isUploadEnabled = () => {
    const uploadedFileTypes = Object.keys(files);
    const validFiles = uploadedFileTypes.filter(
      (fileType) => files[fileType] && files[fileType].status === "valid"
    );

    return validFiles.length > 0;
  };

  const handleUpload = async () => {
    if (!isUploadEnabled()) return;

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const allData = Object.values(files).flatMap((file) => file.data || []);

      if (allData.length === 0) {
        alert("Ingen data å laste opp.");
        return;
      }

      const batchSize = 1000;
      const totalBatches = Math.ceil(allData.length / batchSize);

      for (let i = 0; i < totalBatches; i++) {
        const batch = allData.slice(i * batchSize, (i + 1) * batchSize);
        setUploadProgress((i / totalBatches) * 100);

        const tripResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/rig/uploadTrips`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(batch),
          }
        );

        if (!tripResponse.ok) {
          const errorText = await tripResponse.text();
          throw new Error(
            `Feil ved opplasting av batch ${i + 1}: ${errorText}`
          );
        }
      }

      setUploadProgress(100);
      alert("Filer lastet opp og data importert!");
      setFiles({});
    } catch (error) {
      if (error instanceof Error) {
        alert(`En feil oppstod: ${error.message}`);
      } else {
        alert("En ukjent feil oppstod.");
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileAccepted = async (file: File | null, fileTypeKey: string) => {
    if (!file) {
      setFiles((prev) => {
        const newFiles = { ...prev };
        delete newFiles[fileTypeKey];
        return newFiles;
      });
      return;
    }

    const fileType = FILE_TYPES[fileTypeKey];
    setFiles((prev) => ({
      ...prev,
      [fileTypeKey]: { name: file.name, status: "validating" },
    }));

    try {
      const validationResult = await validateExcelFile(
        file,
        fileType,
        cabMapping,
        tripTypeMapping
      );

      setFiles((prev) => ({
        ...prev,
        [fileTypeKey]: {
          name: file.name,
          status: validationResult.isValid ? "valid" : "invalid",
          error: validationResult.error,
          data: validationResult.processedData?.map((trip) => ({
            ...trip,
            TripType: tripTypeMapping.typeToId[fileType.type] || 0,
          })),
          totalAmount: validationResult.totalAmount,
        },
      }));
    } catch (error) {
      setFiles((prev) => ({
        ...prev,
        [fileTypeKey]: {
          name: file.name,
          status: "invalid",
          error:
            error instanceof Error ? error.message : "En ukjent feil oppstod",
        },
      }));
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      {(isCabMappingLoading || isTripTypesLoading) && (
        <div className="text-center mb-4">
          <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent mx-auto mb-2" />
          <p className="text-gray-600">Laster inn nødvendig data...</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {Object.entries(FILE_TYPES).map(([key, fileType]) => (
          <SingleFileDropzone
            key={key}
            fileType={fileType}
            onFileAccepted={(file) => handleFileAccepted(file, key)}
            currentFile={files[key] || null}
            cabMapping={cabMapping}
            tripTypeMapping={tripTypeMapping}
          />
        ))}
      </div>

      <div className="flex justify-between mt-6">
        <button
          onClick={handleUpload}
          disabled={!isUploadEnabled() || isUploading}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300"
        >
          {isUploading ? "Laster opp..." : "Last opp filer"}
        </button>

        <button
          onClick={handleNavigateToSummary}
          className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        >
          Gå til fakturaoppsummering
        </button>
      </div>
    </div>
  );
}

export default FileUpload;
