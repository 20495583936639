export const normalizePermitNumber = (permitNumber: string): string => {
  if (!permitNumber) return permitNumber;
  permitNumber = permitNumber.toString().toUpperCase().replace(/\s+/g, "");

  const loyeNummer = "420300";
  const forenkletLoyeRegexPattern = /^(AG\d{1,4})$/;
  const loyeRegexPattern = /^[A-Z]{2}\d{10}$|^[A-Z]-\d{8}$/;
  const loyeMedKRegexPattern = /^(K\d{1,3}|K-\d{1,3})$/;

  if (permitNumber.match(loyeRegexPattern)) return permitNumber;
  if (permitNumber.match(forenkletLoyeRegexPattern)) {
    const fylkePrefix = permitNumber.substring(0, 2);
    const identifikasjonsNummer = parseInt(permitNumber.substring(2), 10);
    const formattertNummer = identifikasjonsNummer.toString().padStart(4, "0");
    return `${fylkePrefix}${loyeNummer}${formattertNummer}`;
  }
  if (permitNumber.match(loyeMedKRegexPattern)) {
    permitNumber = permitNumber.replace("-", "");
    const identifikasjonsNummer = parseInt(permitNumber.substring(1), 10);
    return `K-10030${identifikasjonsNummer.toString().padStart(3, "0")}`;
  }
  return permitNumber;
};
