import React from "react";
import { useDropzone } from "react-dropzone";
import { Upload, FileCheck, AlertCircle, X } from "lucide-react";

interface FileType {
  name: string;
}

interface UploadedFile {
  name: string;
  status: "validating" | "valid" | "invalid";
  error?: string;
  totalAmount?: number; // ✅ Legger til totalAmount
}

interface TripTypeMapping {
  typeToId: Record<string, number>;
}

interface SingleFileDropzoneProps {
  fileType: FileType;
  onFileAccepted: (file: File | null) => void;
  currentFile: UploadedFile | null;
  cabMapping: Record<string, number>;
  tripTypeMapping: TripTypeMapping;
}

const SingleFileDropzone: React.FC<SingleFileDropzoneProps> = ({
  fileType,
  onFileAccepted,
  currentFile,
  cabMapping,
  tripTypeMapping,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [".xls", ".xlsx"],
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onFileAccepted(acceptedFiles[0]);
      }
    },
  });

  return (
    <div className="relative mb-5">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors duration-200 ${
          isDragActive
            ? "border-blue-500 bg-blue-50"
            : "border-gray-300 hover:border-blue-400"
        } ${currentFile ? "bg-gray-50" : "bg-white"}`}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center gap-2">
          {currentFile ? (
            <>
              {currentFile.status === "valid" ? (
                <>
                  <FileCheck className="w-8 h-8 text-green-500" />
                  <p className="text-sm text-gray-600">{currentFile.name}</p>
                  {currentFile.totalAmount !== undefined && (
                    <p className="text-sm font-medium text-green-600">
                      Sum:{" "}
                      {currentFile.totalAmount.toLocaleString("nb-NO", {
                        style: "currency",
                        currency: "NOK",
                      })}
                    </p>
                  )}
                </>
              ) : currentFile.status === "invalid" ? (
                <>
                  <AlertCircle className="w-8 h-8 text-red-500" />
                  <p className="text-sm text-gray-600">{currentFile.name}</p>
                  {currentFile.error && (
                    <p className="text-sm text-red-500">{currentFile.error}</p>
                  )}
                </>
              ) : (
                <>
                  <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent" />
                  <p className="text-sm text-gray-600">Validerer fil...</p>
                </>
              )}
            </>
          ) : (
            <>
              <Upload className="w-8 h-8 text-gray-400" />
              <p className="text-sm text-gray-600">
                Dra og slipp {fileType.name}-fil her, eller klikk for å velge
              </p>
            </>
          )}
        </div>
      </div>
      {currentFile && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onFileAccepted(null);
          }}
          className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default SingleFileDropzone;
