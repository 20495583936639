export const parseExcelDate = (dateField: string | number): string | null => {
  if (!dateField) return null;

  if (Array.isArray(dateField)) {
    console.warn("ℹ️ Dato er en liste, henter første element:", dateField);
    dateField = dateField[0];
  }

  dateField = dateField.toString().trim();

  if (!isNaN(Number(dateField)) && Number(dateField) > 30000) {
    const excelEpoch = new Date(Date.UTC(1900, 0, 1));
    const dateOffset = Number(dateField) - 2;
    return new Date(excelEpoch.getTime() + dateOffset * 86400000).toISOString();
  }

  const dateMatch = dateField.match(
    /^(\d{2})\.(\d{2})\.(\d{4})(?:\s+(\d{2}):(\d{2}))?$/
  );
  if (!dateMatch) {
    console.warn("❌ Ukjent datoformat:", dateField);
    return null;
  }

  const [, day, month, year, hour = "00", minute = "00"] = dateMatch;
  return new Date(
    Date.UTC(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      parseInt(hour, 10),
      parseInt(minute, 10),
      0
    )
  ).toISOString();
};
