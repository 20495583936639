import { useState, useEffect } from "react";
import { cabService } from "../functions/services/cab.service";

export const useCabMapping = () => {
  const [cabMapping, setCabMapping] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCabMappings = async () => {
      try {
        const response = await cabService.getCabsWithPermitNumbers();

        if (!Array.isArray(response)) {
          throw new Error("Ugyldig responsformat fra API");
        }

        const mapping = response.reduce<Record<string, number>>((map, cab) => {
          if (cab.permitNumber && cab.cabId) {
            map[String(cab.permitNumber)] = cab.cabId;
          }
          return map;
        }, {});

        setCabMapping(mapping);
      } catch (error) {
        setError("Feil ved henting av løyvenummer-mapping");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCabMappings();
  }, []);

  return { cabMapping, isLoading, error };
};
