import { useState, useEffect } from "react";
import axios from "axios";
import { authHeader } from "../functions/_helpers"; // Importer authHeader hvis du har den

interface TripTypeMapping {
  typeToId: Record<string, number>;
  idToType: Record<number, string>;
}

export const useTripTypes = () => {
  const [tripTypeMapping, setTripTypeMapping] = useState<TripTypeMapping>({
    typeToId: {},
    idToType: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTripTypes = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/rig/getTripTypes",
          {
            headers: authHeader(),
          }
        );

        const data = response.data;

        const mapping = data.reduce(
          (acc: TripTypeMapping, item: { type: string; id: number }) => {
            acc.typeToId[item.type] = item.id;
            acc.idToType[item.id] = item.type;
            return acc;
          },
          { typeToId: {}, idToType: {} }
        );

        setTripTypeMapping(mapping);
      } catch (error) {
        setError("Feil ved henting av turtyper");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTripTypes();
  }, []);

  return { tripTypeMapping, isLoading, error };
};
